import { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";

import { YourBalance } from "./your-balance";
import { TopUpBtn } from "./top-up-btn";
import { Subscribe } from "./subscribe";
import { BloombergSubscription } from "./bloomberg-subscription";

export type ShopProps = {
  containerSx?: SxProps;
};

export const Shop = ({ containerSx = {} }: ShopProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ ...containerSx }}>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
          py: 4,
          px: { xs: 5, lg: 9 },
        }}
      >
        <YourBalance />

        <Box sx={{ ml: "auto" }}>
          <TopUpBtn />
        </Box>
      </Box> */}

      <BloombergSubscription />
      {/* <Subscribe /> */}
    </Box>
  );
};
