import { useCallback } from "react";
import { Avatar, Box, ButtonBase, alpha } from "@mui/material";

export type EpisodeItemProps = {
  itemData: any;
  active: any;
  setCurrentId: any;
  index: number;
  setCurrentIndex: any;
};

export const EpisodeItem = ({ itemData, active, setCurrentId, index, setCurrentIndex }: EpisodeItemProps) => {
  const onItem = useCallback((id: string, index: number) => {
    if (id) {
      setCurrentId(id);
      setCurrentIndex(index);
    }
  }, []);

  return (
    <Box sx={{ p: 1 }}>
      <ButtonBase
        onClick={() => onItem(itemData?.id, index)}
        style={{ width: 260 }}
        sx={{
          borderRadius: 1.5,
        }}
      >
        <Avatar
          sx={{
            width: 260,
            height: 150,
            objectFit: "cover",
            borderRadius: 1.5,

            ...(active
              ? {
                  outline: "3px solid white",
                }
              : {}),

            "&:hover": {
              ...(!active ? { outline: `3px solid ${alpha("#fff", 0.2)}` } : {}),
            },
          }}
          src={itemData?.thumbnail}
        />
      </ButtonBase>
    </Box>
  );
};
