import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { NAV_BAR_HEIGHT } from "../../../constants";
import { FlexRow } from "../../FlexRow";
import { Logo } from "../../logo";
import { StreamButton, NotificationsButton, ProfileButton, TrendsListButton } from "./buttons";
import { SearchInputDesktop, SearchButton, SearchInputMobile } from "./search";
import { Drawers } from "./drawers";
import { usePrivateNavbarContext } from "./PrivateNavbarContext";

export const PrivateNavbarUi = () => {
  const { activeDrawer, updateContext: updateNavbarContext } = usePrivateNavbarContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backdropFilter: "blur(40px)",
          height: NAV_BAR_HEIGHT,
          px: { xs: 0, md: 3 },
          pt: { xs: 0.5, sm: 0 },
          backgroundColor: "rgba(15, 18, 43, 0.6)",
          backgroundImage: "none",
          boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.15)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
        }}
      >
        <Toolbar>
          <FlexRow sx={{ position: "relative", width: "100%", height: "100%" }}>
            <FlexRow
              sx={{
                columnGap: 2,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                onClick={() => {
                  updateNavbarContext({ activeDrawer: undefined });
                }}
                sx={{
                  display: {
                    xs: activeDrawer === "search-result" ? "none" : "inline-flex",
                    sm: "inline-flex",
                    md: activeDrawer === "search-result" ? "none" : "inline-flex",
                    lg: "inline-flex",
                  },
                }}
              >
                <Logo />
              </Box>

              <FlexRow
                sx={{
                  gap: 0.8,
                  ml: 3,
                  display: { xs: "none", md: activeDrawer === "search-result" ? "none" : "flex", lg: "flex" },
                }}
              >
                <Button
                  variant={pathname === "/home" ? "contained" : "text"}
                  sx={{ borderRadius: 20, px: 2, color: pathname !== "/home" ? "#fff" : undefined }}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Community
                </Button>

                <Button
                  variant={pathname === "/discover" ? "contained" : "text"}
                  sx={{ borderRadius: 20, px: 2, color: pathname !== "/discover" ? "#fff" : undefined }}
                  onClick={() => {
                    navigate("/discover");
                  }}
                >
                  Discover
                </Button>

                <Button
                  variant={
                    pathname === "/live/radio/home" ||
                    pathname === "/live/radio/trending" ||
                    pathname === "/live/radio/following"
                      ? "contained"
                      : "text"
                  }
                  sx={{
                    borderRadius: 20,
                    px: 2,
                    color:
                      pathname !== "/live/radio/home" &&
                      pathname !== "/live/radio/trending" &&
                      pathname !== "/live/radio/following"
                        ? "#fff"
                        : undefined,
                  }}
                  onClick={() => {
                    navigate("/live/radio/home");
                  }}
                >
                  Listen
                </Button>
              </FlexRow>

              <Box
                sx={{
                  display: {
                    xs: activeDrawer === "search-result" ? "none" : "inline-flex",
                    sm: "none",
                    md: activeDrawer === "search-result" ? "none" : "inline-flex",
                    lg: "none",
                  },
                  alignItems: "center",
                  ml: "auto",
                  mr: { xs: 0, lg: "auto" },
                }}
              >
                <SearchButton />
              </Box>

              <Box
                sx={{
                  display: { xs: "none", sm: "flex", md: "none", lg: "flex" },
                  alignItems: "center",
                  ml: "auto",
                  mr: { xs: 0, md: "auto" },
                }}
              >
                <SearchInputDesktop />
              </Box>

              <Box
                sx={{
                  display: { xs: "none", md: activeDrawer === "search-result" ? "none" : "block", lg: "block" },
                }}
                onClick={() => {
                  updateNavbarContext({ activeDrawer: undefined });
                }}
              >
              {/*   <StreamButton /> */}
              </Box>

              <Box
                sx={{
                  display: {
                    xs: activeDrawer === "search-result" ? "none" : "block",
                    sm: "block",
                    md: activeDrawer === "search-result" ? "none" : "block",
                    lg: "block",
                  },
                }}
                onClick={() => {
                  updateNavbarContext({ activeDrawer: undefined });
                }}
              >
                <NotificationsButton />
              </Box>

              <Box
                sx={{
                  display: {
                    xs: activeDrawer === "search-result" ? "none" : "block",
                    sm: "block",
                    md: pathname === "/home" ? "none" : activeDrawer === "search-result" ? "none" : "block",
                    lg: pathname === "/home" ? "none" : "block",
                  },
                }}
              >
                <TrendsListButton />
              </Box>

              <Box
                sx={{
                  display: {
                    xs: activeDrawer === "search-result" ? "none" : "block",
                    md: activeDrawer === "search-result" ? "none" : "block",
                    lg: "block",
                  },
                }}
              >
                <ProfileButton />
              </Box>
            </FlexRow>

            {activeDrawer === "search-result" && <SearchInputMobile />}
          </FlexRow>
        </Toolbar>
      </AppBar>

      <Drawers />
    </>
  );
};
