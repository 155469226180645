import React, { useEffect, useRef } from "react";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";

import { NAV_BAR_HEIGHT } from "../../constants";
import { LiveCarousel, ScrollToTopButton } from "../../components";
import { usePrivateLayoutContext } from "../../layouts";
import { FeedContainer } from "./FeedContainer";
import { CardsColumn } from "./CardsColumn";
import { JoinDiscordButton } from "./JoinDiscordButton";
import { useHomePageContext } from "./HomePageContext";
import { apiClient } from "../../config";

export const HomePageUi = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const { showLiveOnBoltInCardsColumn, updateContext: updateHomePageContext } = useHomePageContext();

  const [tabIndex] = React.useState(0);

  // TODO: confirm this behavior
  // useCloseMenuByScroll();

  const isMd = useMediaQuery("(max-width:1024px)");

  const slideWidth = isMd ? undefined : isMobile ? 292 : 450;

  const slideHeight = isMd ? undefined : isMobile ? 198 : 306;

  const targetRef: any = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries: any) => {
      const [entry] = entries;

      updateHomePageContext({ showLiveOnBoltInCardsColumn: !entry.isIntersecting });
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  interface Ad {
    assetUrl: string;
    placement: string;
  }

  const [topAds, setTopAds] = React.useState<Ad[]>([]);
  const [sideAds, setSideAds] = React.useState<Ad[]>([]);
  const [postAds, setPostAds] = React.useState<Ad[]>([]);
  const [topAdIndex, setTopAdIndex] = React.useState(0);
  const [sideAdIndex, setSideAdIndex] = React.useState(0);
  const [postAdIndex, setPostAdIndex] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apiClient.get(`/advertisements/get?limit=10&page=1&skip=0`);
      setTopAds(data?.data.filter((ad: any) => ad.placement === "top"));
      setSideAds(data?.data.filter((ad: any) => ad.placement === "side"));
      setPostAds(data?.data.filter((ad: any) => ad.placement === "post"));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTopAdIndex((prevIndex) => (prevIndex + 1) % topAds.length);
      setSideAdIndex((prevIndex) => (prevIndex + 1) % sideAds.length);
      setPostAdIndex((prevIndex) => (prevIndex + 1) % postAds.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [topAds, sideAds, postAds]);

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        {topAds[topAdIndex] && topAds?.[topAdIndex]?.assetUrl && (
          <div
            style={{
              width: "100%",
              background: "#ccc",
              color: "#000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <img src={topAds?.[topAdIndex]?.assetUrl} alt="Top Ad" style={{ width: "100%" }} />
          </div>
        )}
        <LiveCarousel showTitle slideWidth={slideWidth} slideHeight={slideHeight} />

        <Box
          ref={targetRef}
          sx={{
            position: "absolute",
            top: "60%",
            width: 1,
            height: "1px",
            zIndex: -1,
          }}
        />
      </Box>

      <Container maxWidth="lg" sx={{ pt: { xs: 2, sm: 3 } }}>
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Grid item xs={12} sm={8} md={6}>
            <FeedContainer tabIndex={tabIndex} postAds={postAds} postAdIndex={postAdIndex} />
          </Grid>

          <Grid
            item
            md={6}
            lg={5}
            xl={4.5}
            display={{ xs: "none", md: "block" }}
            sx={{
              position: showLiveOnBoltInCardsColumn ? "sticky" : "relative",
              alignSelf: "flex-start",
              top: showLiveOnBoltInCardsColumn ? NAV_BAR_HEIGHT : 0,
              maxHeight: showLiveOnBoltInCardsColumn ? `calc(100vh - ${navbar__height}px)` : undefined,
              overflow: "auto",
              scrollbarWidth: "none",
            }}
          >
            {sideAds[sideAdIndex] && sideAds?.[sideAdIndex]?.assetUrl && (
              <div
                style={{
                  width: "100%",
                  background: "#ccc",
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <img src={sideAds?.[sideAdIndex]?.assetUrl} alt="Side Ad" style={{ width: "100%" }} />
              </div>
            )}
            <CardsColumn />
          </Grid>
        </Grid>
      </Container>

      {/* <JoinDiscordButton /> */}

      <ScrollToTopButton sx={{ bottom: { xs: `calc(2% + 65px)`, md: "2%" } }} />
    </Box>
  );
};

const useCloseMenuByScroll = () => {
  const { updatePrivateLayout } = usePrivateLayoutContext();

  useEffect(() => {
    const handleEvent = (event?: any) => {
      updatePrivateLayout({ leftMenubar__visible: false });

      window.removeEventListener("scroll", () => {});
      window.removeEventListener("click", handleEvent);
    };

    setTimeout(() => {
      window.addEventListener("click", handleEvent);
      window.addEventListener("scroll", (e) => {
        if (window.scrollY > 130) {
          handleEvent();
        }
      });
    }, 500);

    return () => {
      window.removeEventListener("scroll", () => {});
      window.removeEventListener("click", handleEvent);
    };
  }, []);
};
