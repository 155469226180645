import { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import Subscription from "../../../../assets/pngs/shop/subscription.png";
import ScreenTime from "../../../../assets/pngs/shop/screentime.png";
import Terminal from "../../../../assets/pngs/shop/terminal.png";
import buyNow from "../../../../assets/svgs/buy-now.svg";
// import Rating from "../../../../assets/pngs/shop/rating.png";
import { useEntityLayoutContext } from "../../../../layouts/entity-layout/EntityLayoutContext";

export const BloombergSubscription = () => {
  const [requestDemo, setRequestDemo] = useState(false);
  const { entity } = useEntityLayoutContext();
  return (
    <Grid container>
      {requestDemo ? (
        <Box>
          <Button className="outline-btn" onClick={() => setRequestDemo(false)}>
            <KeyboardArrowLeftIcon /> Back
          </Button>
          <Box padding="20px 14px">
            <Box mb="25px">
              <img src={ScreenTime} alt="Subscription" width="100%" style={{ display: "block" }} />
            </Box>
            <Box>
              <Typography marginBottom="5px" textAlign="center" fontSize="24px" fontWeight="500" lineHeight="28px">
                Bloomberg Terminal
              </Typography>
              <Typography textAlign="center" fontSize="20px" fontWeight="400" lineHeight="28px" color="#A3AED0">
                A global icon of progress
              </Typography>
              <Typography
                margin="25px 0 15px 0"
                textAlign="center"
                fontSize="20px"
                fontWeight="400"
                lineHeight="28px"
                color="#A3AED0"
              >
                Scan QR code to learn more
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb="25px">
                <QRCode
                  value="https://www.bloomberg.com/subscriptions/uk?utm_medium=cpc_search&utm_campaign=BR_ACQ_BRAND_BRANDGENERALX_EVG_XXXX_XXX_COGBR_EN_EN_X_BLOM_GO_SE_XXX_XXXXXXXXXX"
                  size={150}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box padding="20px">
          {entity?.data?.products?.length > 0 &&
            entity?.data?.products?.map((product: any) => (
              <Box
                key={product?.id}
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap="20px"
                mb="20px"
                // onClick={() => setRequestDemo(true)}
                // style={{ cursor: "pointer" }}
              >
                <Box width="100%" display="flex" alignItems="center" gap="25px">
                  <Box width="40%">
                    <img src={product?.assetUrl} alt="Subscription" width="100%" style={{ display: "block" }} />
                  </Box>
                  <Box width="60%">
                    <Typography variant="h4" fontSize="20px" fontWeight="500" lineHeight="28px" mb="15px">
                      {product?.title}
                    </Typography>
                    <Box display="flex" alignItems="center" gap="10px" mb="25px">
                      <Rating name="read-only" value={product?.rating} readOnly />
                      {/* <span style={{ color: "#A3AED0", fontSize: "16px" }}>{product.rating} Reviews</span> */}
                    </Box>
                    <Typography fontSize="32px" lineHeight="28px" fontWeight="500">
                      <span style={{ color: "#A3AED0", fontSize: "24px", fontWeight: "400" }}> </span> {product?.price}{" "}
                      {/* <span style={{ color: "#A3AED0", fontSize: "24px", fontWeight: "400" }}> GBP</span> */}
                    </Typography>
                  </Box>
                </Box>

                <Button
                  style={{ width: "100%", marginTop: "0", alignItems: "center", gap: "10px" }}
                  onClick={() => window.open(product?.link, "_blank")}
                  className="request-demo-btn"
                >
                  <img src={buyNow} alt="But Now" /> Buy now
                </Button>
              </Box>
            ))}
          {/* <Box display="flex" alignItems="center" gap="25px" mb="20px">
            <Box width="40%">
              <img src={Subscription} alt="Subscription" width="100%" style={{ display: "block" }} />
            </Box>
            <Box width="60%">
              <Typography variant="h4" fontSize="20px" fontWeight="500" lineHeight="28px" mb="15px">
                Bloomberg Business week - 1 Year Digital Subscription
              </Typography>
              <Box display="flex" alignItems="center" gap="10px" mb="25px">
                <img src={Rating} alt="Rating" width="80px" style={{ display: "block" }} />{" "}
                <span style={{ color: "#A3AED0", fontSize: "16px" }}>228 Reviews</span>
              </Box>
              <Typography fontSize="32px" lineHeight="28px" fontWeight="500">
                <span style={{ color: "#A3AED0", fontSize: "24px", fontWeight: "400" }}>£ </span> 199.99{" "}
                <span style={{ color: "#A3AED0", fontSize: "24px", fontWeight: "400" }}> GBP</span>
              </Typography>
            </Box>
          </Box> */}

          <Box padding="20px 14px" bgcolor="rgba(255, 255, 255, 0.05)" borderRadius="15px">
            <Box mb="25px">
              <img src={Terminal} alt="Subscription" width="100%" style={{ display: "block" }} />
            </Box>
            <Box>
              <Typography marginBottom="5px" textAlign="center" fontSize="24px" fontWeight="500" lineHeight="28px">
                Bloomberg Terminal
              </Typography>
              <Typography textAlign="center" fontSize="20px" fontWeight="400" lineHeight="28px" color="#A3AED0">
                A global icon of progress
              </Typography>
              <Button onClick={() => setRequestDemo(true)} className="request-demo-btn">
                Request a demo
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
};
