import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import "@draft-js-plugins/hashtag/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";

import "@rainbow-me/rainbowkit/styles.css";

import { RainbowKitProvider, connectorsForWallets } from "@rainbow-me/rainbowkit";

import { walletConnectWallet, metaMaskWallet, okxWallet } from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygon, bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { darkTheme, lightTheme } from "./theme/theme";
import { ColorModeContext, SocketContextProvider } from "./hooks";
import { store, persistor } from "./store";
import { RootRouter } from "./routes";

const { chains, publicClient } = configureChains(
  [mainnet, polygon, bsc],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY as string }), publicProvider()]
);

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);
  const [rootStore, setRootStore] = React.useState<any | undefined>(undefined);
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(() => (mode === "dark" ? darkTheme : lightTheme), [mode]);

  React.useEffect(() => {
    const initStore = async () => {
      setRootStore({ store, persistor });
    };
    initStore();
    // ReactGA.initialize("G-NVGR8YEL9Y");
    ReactGA.initialize("G-8D1BG535Z5");
    
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Elements stripe={stripePromise}>
          <Provider store={rootStore.store}>
            <PersistGate loading={null} persistor={rootStore.persistor}>
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <SocketContextProvider>
                    <BrowserRouter>
                      <CssBaseline />
                      <RootRouter />
                    </BrowserRouter>
                  </SocketContextProvider>
                  <ToastContainer />
                </ThemeProvider>
              </ColorModeContext.Provider>
            </PersistGate>
          </Provider>
        </Elements>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
