import { useEffect, useState } from "react";
import { Avatar, Box, Button, SxProps, Typography } from "@mui/material";
import AdjustIcon from "../../../assets/svgs/adjust-icon.svg";
import exampleAvatar from "../../../assets/pngs/example-avatar.png";

export type RewardsProps = {
  containerSx?: SxProps;
};

export const ChatFont = ({ containerSx = {} }: RewardsProps) => {
  const [fontSize, setFontSize] = useState(() => {
    const storedFontSize = localStorage.getItem("chatFontSize");
    return storedFontSize ? parseInt(storedFontSize, 10) : 16; // Initial size from storage or default
  });

  const handleIncrement = () => {
    setFontSize((prevSize) => Math.min(prevSize + 2, 30)); // Limit to max 30px
  };

  const handleDecrement = () => {
    setFontSize((prevSize) => Math.max(prevSize - 2, 12)); // Limit to min 12px
  };

  useEffect(() => {
    if (fontSize) {
      localStorage.setItem("chatFontSize", fontSize.toString()); // Update localStorage
    }
  }, [fontSize]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 6,
        textAlign: "center",
        p: 4,
        ...containerSx,
      }}
    >
      <Box display="flex" gap="10px" alignItems="center">
        <img src={AdjustIcon} alt="Adjust Icon" />
        <Typography color="#A3AED0" fontSize="24px">
          Adjust text size
        </Typography>
      </Box>
      <Box gap="10px" display="flex" alignItems="center">
        <Button
          onClick={handleIncrement}
          className="adjust-btn"
          style={{ color: "#161A2F", backgroundColor: "#F0F0F1" }}
        >
          A+
        </Button>
        <Button
          onClick={handleDecrement}
          className="adjust-btn"
          style={{ color: "#F0F0F1", backgroundColor: "#161A2F" }}
        >
          A-
        </Button>
      </Box>

      <div className="example_view" style={{ fontSize: `${fontSize}px` }}>
        <div className="example_avatar">
          <Avatar alt="Example" src={exampleAvatar} />
        </div>
        <div className="example_text">
          <b>Joan</b> Deadline extended ! good news? Think so!
        </div>
      </div>
    </Box>
  );
};
