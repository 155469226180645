import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { authSelector } from "../store/auth/selector";
import { PrivateLayout, PublicLayout, LiveLayout, EntityLayout, SettingsLayout } from "../layouts";

import {
  ChatWindowPopout,
  Feed,
  HomePage,
  LoginPage,
  ResetPasswordPage,
  VideoWindowPopout,
  UnsubscribeEmailPage,
  SettingsPage,
  TvAuthPage,
  EarningsPage,
  AnalyticsPage,
  SecurityPage,
  Studio,
  CollectionPage,
} from "../pages";
import { LivePage } from "../pages/live";
import SearchPage from "../pages/search";
import { EmailVerifyPage } from "../pages/emailVerify";
import { OnboardingPage } from "../pages/onboarding/OnboardingPage";
import { connectSocket } from "../store/socket/actions";
import { socketSelector } from "../store/socket/selector";
import { Notifications } from "../pages/notifications";
import { setStreamDataAction } from "../store/streamer/actions";
import { FlashbackPage } from "../pages/flashback";
import { DiscoverPage } from "../pages/discover";
import { BloombergEpisodePage, BloombergEpisodesPage } from "../pages/partners/bloomberg";
import { SignUpPage } from "../pages/signUp";
import { OneFootballEpisodePage, OneFootballEpisodesPage } from "../pages/partners/oneFootball";

export const RootRouter = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(authSelector);
  const { socketConnected } = useSelector(socketSelector);

  const handleTabClose = (event: Event) => {
    dispatch(setStreamDataAction({ url: undefined }));
  };

  React.useEffect(() => {
    if (isLoggedIn && !socketConnected) {
      dispatch(connectSocket());
    }

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [isLoggedIn, socketConnected]);

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/email-verify" element={<EmailVerifyPage />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/unsubscribe/email-notification" element={<UnsubscribeEmailPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/*" element={<Navigate replace to={isLoggedIn ? "/discover" : "/login"} />} />
      {!!isLoggedIn && (
        <Route element={<PrivateLayout />}>
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/tv-login" element={<TvAuthPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/feed/:feedId" element={<Feed />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/discover" element={<DiscoverPage />} />
          <Route path="/live/:tab" element={<LiveLayout />}>
            <Route path=":category" element={<LivePage />} />
          </Route>
          <Route path="/live" element={<Navigate to="/live/tv/home" />} />
          <Route path="/login" element={<Navigate to="/home" />} />
          <Route path="/signup" element={<Navigate to="/home" />} />
          <Route path="/:boltId" element={<EntityLayout />} />
          <Route path="/flashback/:username" element={<FlashbackPage />} />
          <Route path="/settings" element={<SettingsLayout />}>
            <Route path=":category" element={<SettingsPage />} />
            <Route path="security" element={<SecurityPage />} />
          </Route>
          <Route path="/user">
            <Route path="earnings" element={<EarningsPage />} />
            <Route path="analytics" element={<AnalyticsPage />} />
          </Route>
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/:feedId" element={<Feed />} />
          <Route path="/studio" element={<Studio />} />
          {/* Episode pages */}
          <Route path="/p/bloomberg/:categorySlug" element={<BloombergEpisodesPage />} />
          <Route path="/p/bloomberg/:categorySlug/:channelSlug" element={<BloombergEpisodePage />} />
          <Route path="/p/one-football/:categorySlug" element={<OneFootballEpisodesPage />} />
          <Route path="/p/one-football/:categorySlug/:channelSlug" element={<OneFootballEpisodePage />} />
          {/* Collection page */}
          <Route path="/c/:collectionSlug" element={<CollectionPage />} />
          <Route path="/*" element={<Navigate to="/home" />} />
        </Route>
      )}
      {!!isLoggedIn && (
        <>
          <Route path="/chatpopout" element={<ChatWindowPopout />} />
          <Route path="/playerpopout/:boltId" element={<VideoWindowPopout />} />
        </>
      )}
    </Routes>
  );
};
