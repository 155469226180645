import { useEffect, useState } from "react";
import { Box, SxProps, Typography, alpha } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import ScreenTime from "../../../assets/pngs/shop/screentime.png";

import { HooraySvg } from "./HooraySvg";
import { ClaimBtn } from "./ClaimBtn";

export type RewardsProps = {
  containerSx?: SxProps;
};

export const Rewards = ({ containerSx = {} }: RewardsProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 6,
        textAlign: "center",
        p: 4,
        ...containerSx,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
        {/* <HooraySvg /> */}

        <Typography sx={{ fontSize: 24, fontWeight: 600 }}>Bloomberg UK</Typography>

        <img src={ScreenTime} alt="Subscription" width="100%" style={{ display: "block" }} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25 }}>
            Want free tickets for <br />
            Bloomberg Screentime 2025?
          </Typography>
          <Typography sx={{ fontSize: 14, color: alpha("#ffffff", 0.85) }}>
            Get Free tickets by completing this contest.
          </Typography>
        </Box>

        <Typography sx={{ fontSize: 14, color: "#A3AED0" }}>Scan QR code to Enter</Typography>
        <Box sx={{ borderRadius: "10px", overflow: "hidden", lineHeight: 0 }}>
          <QRCode value="https://immads.boltplus.tv/bloomberg" size={125} />
        </Box>
      </Box>
    </Box>
  );
};
