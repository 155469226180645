import React, { useMemo } from "react";
import { Box, Tab, TabProps, Tabs, alpha, styled } from "@mui/material";
import { AutoAwesome, ChatBubble, LocalMall, Stars, TextSnippet } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";

import { useSelector } from "react-redux";
import { Chat } from "./chat";
import { Shop } from "./shop";
import { Read } from "./read";
import { Ai } from "./ai";
import { Rewards } from "./rewards";
import { ChatFont } from "./ChatFont";
import { useImmersivePanelContext } from "./ImmersivePanelContext";
import { userSelector } from "../../store/auth/selector";
import { Guest } from "./guest";
import { useEntityLayoutContext } from "../../layouts/entity-layout/EntityLayoutContext";

export const ImmersivePanelUi = () => {
  const { activeTab, chatProps, updateContext: updateImmersivePanelContext } = useImmersivePanelContext();
  const user = useSelector(userSelector);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    updateImmersivePanelContext({ activeTab: newValue });
  };

  const isProd = process.env.REACT_APP_APP_ENV === "prod";
  const { entity } = useEntityLayoutContext();

  const visibleTabs = useMemo(() => {
    // Memoize visible tabs
    return [
      entity?.data?.enableChat && { label: "Chat", component: <Chat {...chatProps} />, icon: <ChatBubble /> },
      entity?.data?.enableShop && !isProd && { label: "Shop", component: <Shop />, icon: <LocalMall /> },
      entity?.data?.enableRead && !isProd && { label: "Read", component: <Read />, icon: <TextSnippet /> },
      entity?.data?.enableAI && { label: "AI", component: <Ai />, icon: <AutoAwesome /> },
      entity?.data?.enableReward && !isProd && { label: "Rewards", component: <Rewards />, icon: <Stars /> },
      entity?.data?.enableChat && !isProd && { label: "Settings", component: <ChatFont />, icon: <SettingsIcon /> },
    ].filter(Boolean); // Filter out null/false entries
  }, [entity, isProd, chatProps]);

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", flex: 1, position: "relative" }}
      className={user?.username === "guest" ? "guest_boxWrapper" : ""}
    >
      <Box
        sx={{
          py: 2,
          width: "100%",
          borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
          position: "sticky",
          top: 0,
          zIndex: 2,
          bgcolor: alpha("#13162C", 0.6),
          backdropFilter: "blur(40px)",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            minHeight: "unset",

            ".MuiTabs-hideScrollbar": {
              top: `0px !important`,
            },

            ".MuiTabs-flexContainer": {
              display: "block",
            },

            ".MuiTabs-scrollButtons": {
              position: "absolute",
              zIndex: 1,
              width: 25,
              height: 25,
              borderRadius: 25,
              bgcolor: "#3C436F",
              opacity: 1,
              top: "50%",
              transform: "translateY(-50%)",

              "&:first-child": {
                left: 12,
              },

              "&:last-child": {
                right: 12,
              },

              "&.MuiTabScrollButton-root.Mui-disabled": {
                opacity: 0,
              },

              "@media (max-width: 599.95px)": {
                display: "inline-flex",
              },
            },

            ".MuiTab-root": {
              mx: 1,

              "&:first-child": {
                ml: 4,
              },

              "&:last-child": {
                mr: 4,
              },
            },

            ".MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {/* {entity && entity.data && entity.data.enableChat && <CustomTab label="Chat" icon={<ChatBubble />} />}

          {entity && entity.data && entity.data.enableShop && !isProd && (
            <CustomTab label="Shop" icon={<LocalMall />} />
          )}

          {entity && entity.data && entity.data.enableRead && !isProd && (
            <CustomTab label="Read" icon={<TextSnippet />} />
          )}

          {entity && entity.data && entity.data.enableAI && <CustomTab label="AI" icon={<AutoAwesome />} />}

          {!isProd && <CustomTab label="Rewards" icon={<Stars />} />} */}

          {visibleTabs.map((tab, index) => (
            <CustomTab key={index} label={tab.label} icon={tab.icon} />
          ))}
        </Tabs>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 10,
            height: "100%",
            width: 24,
            background: "linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
            height: "100%",
            width: 24,
            background:
              "linear-gradient(270deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />
      </Box>
      {console.log("activeTab", activeTab)}
      {/* {entity && entity.data && entity.data.enableChat && activeTab === 0 && <Chat {...chatProps} />}

      {entity && entity.data && entity.data.enableShop && !isProd && activeTab === 1 && <Shop />}

      {entity && entity.data && entity.data.enableRead && !isProd && activeTab === 2 && <Read />}

      {entity && entity.data && entity.data.enableAI && activeTab === (isProd ? 1 : 3) && <Ai />}

      {entity && entity.data && entity.data.enableReward && !isProd && activeTab === 4 && <Rewards />} */}

      {visibleTabs.length > 0 && visibleTabs[activeTab]?.component}

      {user?.username === "guest" && <Guest />}
    </Box>
  );
};

const CustomTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  color: "#fff",
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 6,
  minHeight: 38,
  minWidth: 0,
  flexDirection: "row",
  alignItems: "center",
  columnGap: 8,

  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },

  "&:hover": {
    opacity: 1,
  },

  "&.Mui-selected": {
    backgroundColor: "#FFFFFF",
    color: "#161A2F",
  },

  ".MuiTab-iconWrapper": {
    marginBottom: 0,
  },
}));
